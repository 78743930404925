import { ReactNode } from 'react';

import { StatusCodeErrors } from '@/lib/error-codes.enum';
import error404 from '@/public/images/error404.svg';
import error500 from '@/public/images/error500.svg';

import { ImageWithPlaceholder } from '../ImageWithPlaceholder';
import { PrimaryButton } from '../buttons';
import { PrimaryHeading } from '../typography';

type ErrorContentProps = {
  statusCode: StatusCodeErrors;
  altText: string;
  title: string;
  description: string | ReactNode;
  ctaLabel: string;
};

export default function ErrorContent({
  statusCode,
  altText,
  title,
  description,
  ctaLabel,
}: ErrorContentProps) {
  const handleClickCta = () => {
    if (statusCode === StatusCodeErrors.INTERNAL_SERVER) {
      window.location.reload();
    } else {
      window.location.href = '/';
    }
  };

  return (
    <div className="flex flex-col items-center my-12 md:flex-row max-md:mt-2">
      <ImageWithPlaceholder
        className="w-full md:w-1/2 max-md:mb-8"
        src={statusCode === StatusCodeErrors.NOT_FOUND ? error404 : error500}
        width={290}
        height={221}
        alt={altText}
        priority
      />
      <div className="flex flex-col items-center px-4 text-center md:px-10 lg:px-20">
        <PrimaryHeading className="mb-5">{title}</PrimaryHeading>
        <p className="mb-6 md:mb-8">{description}</p>
        <PrimaryButton onClick={handleClickCta}>{ctaLabel}</PrimaryButton>
      </div>
    </div>
  );
}
